import React, { useState, useEffect } from "react";
import { NavLink, Link, useNavigate, useLocation } from "react-router-dom";
import {
  Menu,
  Search,
  Heart,
  ShoppingBag,
  User,
  Settings,
  Phone,
  Mail,
  X,
} from "lucide-react";
import {
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaLinkedin,
  FaHome,
  FaEnvelope,
  FaBriefcase,
  FaUserFriends,
  FaChevronDown,
} from "react-icons/fa";
import { ChevronDown, ChevronUp } from "lucide-react";
import { Search as SearchIcon } from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";
import { MdCompare } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { getCategories } from "../features/pcategory/pcategorySlice";
import { getAllProducts } from "../features/products/productSlice";

const Header = () => {
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState(""); // To track the search input
  const [noResults, setNoResults] = useState(false); // State to track if no results were found
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const isHomePage = location.pathname === "/";

  // Fetch categories from Redux store
  const { pCategories: categories } = useSelector((state) => state.pcategory);
  const products = useSelector(
    (state) => state.product.products?.products || []
  );

  useEffect(() => {
    // Fetch categories when the component mounts
    dispatch(getCategories());
    dispatch(getAllProducts());
  }, [dispatch]);

  const [isProductsDropdownOpen, setIsProductsDropdownOpen] = useState(true);
  const [isProductssDropdownOpen, setIsProductssDropdownOpen] = useState(false);

  const toggleProductsDropdown = () => {
    setIsProductsDropdownOpen(!isProductsDropdownOpen);
  };

  // Professional color palette
  const colors = {
    primary: "text-blue-800",
    secondary: "text-gray-700",
    accent: "bg-blue-600",
    hover: "hover:bg-blue-50",
    border: "border-gray-200",
  };

  // Refined animation variants
  const backdropVariants = {
    hidden: {
      opacity: 0,
      transition: { duration: 0.3 },
    },
    visible: {
      opacity: 0.6,
      transition: { duration: 0.3 },
    },
  };

  const sidebarVariants = {
    hidden: {
      x: "-100%",
      boxShadow: "0 0 0 rgba(0,0,0,0)",
      transition: {
        type: "tween",
        duration: 0.4,
      },
    },
    visible: {
      x: 0,
      boxShadow: "0 25px 50px -12px rgba(0,0,0,0.25)",
      transition: {
        type: "tween",
        duration: 0.4,
      },
    },
  };

  // Close all overlays
  const closeAllOverlays = () => {
    setIsSearchOpen(false);
    setIsSidebarOpen(true);
    setIsSettingsOpen(false);
  };

  const handleSearch = (e) => {
    e.preventDefault();

    if (searchQuery.trim()) {
      const query = searchQuery.toLowerCase();

      // Normalize strings for better comparison
      const normalize = (text) =>
        typeof text === "string" ? text.toLowerCase().replace(/s$/, "") : "";

      // Match categories
      const matchedCategories = categories.filter((category) =>
        normalize(category.title).includes(normalize(query))
      );

      // Match subcategories
      const matchedSubcategories = categories
        .flatMap((category) => category.subcategories || [])
        .filter(
          (subcategory) =>
            subcategory?.title &&
            normalize(subcategory.title).includes(normalize(query))
        );

      // Match products
      const matchedProducts = products.filter(
        (product) =>
          product?.title && normalize(product.title).includes(normalize(query))
      );

      // Combine results for navigation
      const combinedResults = [
        ...matchedCategories.map((category) => ({
          type: "category",
          data: category,
        })),
        ...matchedSubcategories.map((subcategory) => ({
          type: "subcategory",
          data: subcategory,
        })),
        ...matchedProducts.map((product) => ({
          type: "product",
          data: product,
        })),
      ];

      if (combinedResults.length > 0) {
        setNoResults(false); // Reset noResults state
        const firstResult = combinedResults[0];

        // Navigate based on the first match type
        if (firstResult.type === "category") {
          navigate(
            `/product?category=${encodeURIComponent(firstResult.data.title)}`
          );
        } else if (firstResult.type === "subcategory") {
          navigate(
            `/product?subcategory=${encodeURIComponent(firstResult.data.title)}`
          );
        } else if (firstResult.type === "product") {
          navigate(
            `/product?search=${encodeURIComponent(firstResult.data.title)}`
          );
        }

        setSearchQuery(""); // Clear search input
        setIsSearchOpen(false); // Close search overlay
      } else {
        setNoResults(true); // Set noResults state to true
      }
    }
  };

  return (
    <div className="w-full relative">
      <div className="bg-gradient-to-br from-gray-900 to-black text-white text-sm py-2 border-b border-zinc-800 font-poppins">
        <div className="container mx-auto px-4">
          <div className="flex flex-col md:flex-row justify-between items-center">
            {/* Text Content */}
            <p className="text-center md:text-left mb-2 md:mb-0 text-xs sm:text-sm">
              Welcome you to SPARTANS WEARS
            </p>

            {/* Links and Icons */}
            <div className="flex items-center space-x-4 sm:space-x-6">
              <div className="hidden md:flex items-center space-x-2 sm:space-x-4">
                {/* Social Media Icons */}
                <Link
                  to="#"
                  className="hover:text-blue-400 transition-transform duration-200 transform hover:scale-110"
                >
                  <FaFacebook className="w-4 h-4 sm:w-5 sm:h-5" />
                </Link>
                <Link
                  to="#"
                  className="hover:text-blue-400 transition-transform duration-200 transform hover:scale-110"
                >
                  <FaTwitter className="w-4 h-4 sm:w-5 sm:h-5" />
                </Link>
                <Link
                  to="#"
                  className="hover:text-pink-400 transition-transform duration-200 transform hover:scale-110"
                >
                  <FaInstagram className="w-4 h-4 sm:w-5 sm:h-5" />
                </Link>
                <Link
                  to="#"
                  className="hover:text-blue-400 transition-transform duration-200 transform hover:scale-110"
                >
                  <FaLinkedin className="w-4 h-4 sm:w-5 sm:h-5" />
                </Link>

                {/* Separator */}
                <span className="text-zinc-600 hidden lg:block">|</span>

                {/* Phone Link */}
                <Link
                  to="tel:+92 3105710500"
                  className="flex items-center hover:text-gray-300 transition-colors duration-200 text-xs sm:text-sm"
                >
                  <Phone className="w-3 h-3 sm:w-4 sm:h-4 mr-1 sm:mr-2" />
                  0310-5710500
                </Link>

                {/* Separator */}
                <span className="text-zinc-600 hidden lg:block">|</span>

                {/* Email Link */}
                <Link
                  to="mailto:info@spartanswears.com"
                  className="flex items-center hover:text-gray-300 transition-colors duration-200 text-xs sm:text-sm"
                >
                  <Mail className="w-3 h-3 sm:w-4 sm:h-4 mr-1 sm:mr-2" />
                  info@spartanswears.com
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Main Header */}
      <div
        className={`sticky top-0 z-40 font-poppins ${
          isHomePage
            ? "relative w-full bg-cover bg-center"
            : "bg-white shadow-sm"
        }`}
        style={
          isHomePage
            ? {
                backgroundImage: `url('images/hme.webp')`,
                backgroundColor: "#000",
              }
            : {}
        }
      >
        <div
          className={`${
            isHomePage
              ? "absolute inset-3 bg-black/50"
              : "container mx-auto px-4"
          }`}
        >
          <div
            className={`flex items-center justify-between h-20 ${
              isHomePage ? "bg-transparent" : "bg-whitesmoke"
            }`}
            style={{
              color: isHomePage ? "whitesmoke" : "black",
            }}
          >
            {/* Left Section */}
            <div className="flex items-center space-x-4">
              <button
                onClick={() => {
                  setIsSidebarOpen(true);
                  setIsSearchOpen(false);
                  setIsSettingsOpen(false);
                }}
                className={`p-2 rounded-full transition-transform duration-200 transform hover:scale-110 ${
                  isHomePage
                    ? "hover:bg-gray-500 text-whitesmoke"
                    : "hover:bg-gray-200 text-black"
                }`}
              >
                <Menu className="w-5 h-5 sm:w-6 sm:h-6" />
              </button>
              <Link to="/">
                <img
                  src="/images/SPARTANS.webp"
                  alt="Spartans Logo"
                  className="h-12 w-auto object-contain sm:h-14 md:h-16"
                />
              </Link>
            </div>

            <nav
              className={`hidden md:flex items-center justify-between space-x-8 px-6 py-4 text-sm font-poppins ${
                isHomePage ? "text-white" : "text-black"
              }`}
            >
              <div className="flex items-center space-x-1">
                {/* Home */}
                <div className="relative group">
                  <Link
                    to="/"
                    className={`py-2 px-4 relative transition-all duration-300 ${
                      isHomePage
                        ? "text-whitesmoke font-semibold"
                        : location.pathname === "/"
                        ? "text-black font-semibold"
                        : "text-black"
                    }`}
                  >
                    Home
                    <span
                      className={`absolute bottom-0 left-0 h-0.5 w-full transform scale-x-0 bg-current group-hover:scale-x-100 transition-transform duration-300 origin-left`}
                    ></span>
                  </Link>
                </div>

                {/* About Us */}
                <div className="relative group">
                  <Link
                    to="/about"
                    className={`py-2 px-4 relative transition-all duration-300 ${
                      isHomePage ? "text-whitesmoke" : "text-black"
                    }`}
                  >
                    About Us
                    <span className="absolute bottom-0 left-0 h-0.5 w-full transform scale-x-0 bg-current group-hover:scale-x-100 transition-transform duration-300 origin-left"></span>
                  </Link>
                </div>

                {/* Products Dropdown */}
                <div
                  className="relative group"
                  onMouseEnter={() => setIsProductssDropdownOpen(true)}
                  onMouseLeave={() => setIsProductssDropdownOpen(false)}
                >
                  <button
                    className={`py-2 px-4 flex items-center relative transition-all duration-300 ${
                      isHomePage
                        ? "text-whitesmoke hover:text-blue-300"
                        : "text-black hover:text-blue-500"
                    } group`}
                  >
                    Products
                    {isProductssDropdownOpen ? (
                      <ChevronUp className="ml-1 w-4 h-4" />
                    ) : (
                      <ChevronDown className="ml-1 w-4 h-4" />
                    )}
                    <span
                      className={`absolute bottom-0 left-0 h-0.5 w-full transform scale-x-0 ${
                        isHomePage ? "bg-whitesmoke" : "bg-black"
                      } group-hover:scale-x-100 group-hover:bg-blue-500 transition-transform duration-300 origin-left`}
                    ></span>
                  </button>

                  {/* Dropdown */}
                  {isProductssDropdownOpen && (
                    <div
                      className={`fixed ${
                        isHomePage ? "top-[115px]" : "top-[105px]"
                      } left-0 w-full bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900 shadow-2xl z-50`}
                    >
                      <div className="container mx-auto px-10 py-10 flex items-start justify-between gap-10">
                        {/* Categories Section */}
                        <div className="flex-grow grid grid-cols-3 gap-x-24 gap-y-10">
                          {categories.map((category) => (
                            <div
                              key={category._id}
                              className="space-y-6 text-center flex flex-col items-center animate-fadeIn"
                            >
                              {/* Category Header */}
                              <h4 className="text-2xl font-black text-gray-100 border-b-2 border-gray-700 pb-3 tracking-wider uppercase">
                                {category.title}
                              </h4>
                              {/* Subcategories */}
                              <div className="space-y-3">
                                {category.subcategories.map((subcategory) => (
                                  <Link
                                    key={subcategory._id}
                                    to={`/product?subcategory=${subcategory.title}`}
                                    className="block text-[16px] text-gray-300 hover:text-blue-400 hover:translate-x-2 transition-all duration-300"
                                    onClick={() =>
                                      setIsProductssDropdownOpen(false)
                                    } // Close dropdown on click
                                  >
                                    {subcategory.title}
                                  </Link>
                                ))}
                              </div>
                            </div>
                          ))}
                        </div>

                        {/* Featured Image Section */}
                        <div className="flex-shrink-0 w-1/4 flex flex-col items-center justify-center p-8 bg-gradient-to-br from-gray-800 via-gray-700 to-gray-800 rounded-xl shadow-lg">
                          {/* Image */}
                          <img
                            src="/images/SPARTANS.webp"
                            alt="Category Preview"
                            className="w-full max-h-[300px] rounded-lg shadow-xl transition-transform hover:scale-110 duration-500 object-cover"
                          />
                          {/* Caption */}
                          <div className="text-center mt-6">
                            <p className="text-sm text-gray-400 italic tracking-wide">
                              Featured Category
                            </p>
                            <h3 className="text-xl font-extrabold text-white uppercase tracking-wider">
                              Spartans Collection
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                {/* Contact Us */}
                <div className="relative group">
                  <Link
                    to="/contact"
                    className={`py-2 px-4 relative transition-all duration-300 ${
                      isHomePage ? "text-whitesmoke" : "text-black"
                    }`}
                  >
                    Contact Us
                    <span className="absolute bottom-0 left-0 h-0.5 w-full transform scale-x-0 bg-current group-hover:scale-x-100 transition-transform duration-300 origin-left"></span>
                  </Link>
                </div>
              </div>
            </nav>

            {/* Right Section */}
            <div className="hidden md:flex items-center space-x-4 sm:space-x-6">
              <button
                onClick={() => {
                  setIsSearchOpen(true);
                  setIsSidebarOpen(false);
                  setIsSettingsOpen(false);
                }}
                className={`p-2 rounded-full transition-transform duration-200 transform hover:scale-110 ${
                  isHomePage
                    ? "hover:bg-gray-500 text-whitesmoke"
                    : "hover:bg-gray-200 text-black"
                }`}
              >
                <Search className="w-5 h-5 sm:w-6 sm:h-6" />
              </button>
              <Link
                to="/wishlist"
                className={`p-2 rounded-full transition-transform duration-200 transform hover:scale-110 ${
                  isHomePage
                    ? "hover:bg-gray-500 text-whitesmoke"
                    : "hover:bg-gray-200 text-black"
                }`}
              >
                <Heart className="w-5 h-5 sm:w-6 sm:h-6" />
              </Link>
              <Link
                to="/compare-product"
                className={`p-2 rounded-full transition-transform duration-200 transform hover:scale-110 ${
                  isHomePage
                    ? "hover:bg-gray-500 text-whitesmoke"
                    : "hover:bg-gray-200 text-black"
                }`}
              >
                <MdCompare className="w-5 h-5 sm:w-6 sm:h-6" />
              </Link>
              <Link
                to="/login"
                className={`p-2 rounded-full transition-transform duration-200 transform hover:scale-110 ${
                  isHomePage
                    ? "hover:bg-gray-500 text-whitesmoke"
                    : "hover:bg-gray-200 text-black"
                }`}
              >
                <User className="w-5 h-5 sm:w-6 sm:h-6" />
              </Link>
              <Link
                to="/cart"
                className={`p-2 rounded-full transition-transform duration-200 transform hover:scale-110 ${
                  isHomePage
                    ? "hover:bg-gray-500 text-whitesmoke"
                    : "hover:bg-gray-200 text-black"
                } relative`}
              >
                <ShoppingBag className="w-5 h-5 sm:w-6 sm:h-6" />
                <span className="absolute -top-1 -right-1 bg-black text-white text-xs rounded-full w-4 h-4 sm:w-5 sm:h-5 flex items-center justify-center">
                  0
                </span>
              </Link>
            </div>

            {/* Settings and Search Icon for Mobile */}
            <div className="flex md:hidden items-center space-x-2">
              <button
                onClick={() => {
                  setIsSearchOpen(true);
                  setIsSidebarOpen(false);
                  setIsSettingsOpen(false);
                }}
                className={`p-2 rounded-full transition duration-300 transform hover:scale-110 ${
                  isHomePage
                    ? "hover:bg-gray-500 text-whitesmoke"
                    : "hover:bg-gray-200 text-black"
                }`}
              >
                <Search className="w-5 h-5 sm:w-6 sm:h-6" />
              </button>
              <button
                onClick={() => {
                  setIsSettingsOpen(!isSettingsOpen);
                  setIsSidebarOpen(false);
                  setIsSearchOpen(false);
                }}
                className={`p-2 rounded-full transition duration-300 transform hover:scale-110 ${
                  isHomePage
                    ? "hover:bg-gray-500 text-whitesmoke"
                    : "hover:bg-gray-200 text-black"
                }`}
              >
                <Settings className="w-5 h-5 sm:w-6 sm:h-6" />
              </button>
            </div>
          </div>
        </div>

        {/* Click Outside Functionality */}
        {(isSidebarOpen || isSearchOpen || isSettingsOpen) && (
          <div
            className="fixed inset-0 z-30 bg-black/30 backdrop-blur-sm"
            onClick={closeAllOverlays}
          ></div>
        )}

        {/* Settings Dropdown */}
        {isSettingsOpen && (
          <div
            className="absolute right-4 top-20 bg-white shadow-lg rounded-lg p-4 w-56 sm:w-64 z-50 font-poppins "
            onClick={(e) => e.stopPropagation()}
          >
            <Link
              to="/wishlist"
              className="flex items-center gap-2 p-2 hover:bg-gray-100 rounded transition"
            >
              <Heart className="w-4 h-4 sm:w-5 sm:h-5" />
              <span className="text-sm sm:text-base">Wishlist</span>
            </Link>
            <Link
              to="/compare-product"
              className="flex items-center gap-2 p-2 hover:bg-gray-100 rounded transition"
            >
              <MdCompare className="w-4 h-4 sm:w-5 sm:h-5" />
              <span className="text-sm sm:text-base">Compare</span>
            </Link>
            <Link
              to="/login"
              className="flex items-center gap-2 p-2 hover:bg-gray-100 rounded transition"
            >
              <User className="w-4 h-4 sm:w-5 sm:h-5" />
              <span className="text-sm sm:text-base">Account</span>
            </Link>
            <Link
              to="/cart"
              className="flex items-center gap-2 p-2 hover:bg-gray-100 rounded transition"
            >
              <ShoppingBag className="w-4 h-4 sm:w-5 sm:h-5" />
              <span className="text-sm sm:text-base">Cart</span>
            </Link>
            <a
              href="tel:+92 3105710500"
              className="flex items-center gap-2 p-2 hover:bg-gray-100 rounded transition"
            >
              <Phone className="w-4 h-4 sm:w-5 sm:h-5" />
              <span className="text-sm sm:text-base">0310-5710500</span>
            </a>
            <a
              href="mailto:info@spartanswears.com"
              className="flex items-center gap-2 p-2 hover:bg-gray-100 rounded transition"
            >
              <Mail className="w-4 h-4 sm:w-5 sm:h-5" />
              <span className="text-sm sm:text-base">Email Us</span>
            </a>
            <div className="flex items-center gap-2 mt-2 p-2 hover:bg-gray-100 rounded transition">
              <FaFacebook className="w-4 h-4 sm:w-5 sm:h-5 text-blue-500" />
              <FaTwitter className="w-4 h-4 sm:w-5 sm:h-5 text-blue-400" />
              <FaInstagram className="w-4 h-4 sm:w-5 sm:h-5 text-pink-500" />
              <FaLinkedin className="w-4 h-4 sm:w-5 sm:h-5 text-blue-700" />
            </div>
          </div>
        )}

        {isSearchOpen && (
          <div
            className="fixed inset-0 bg-black/70 backdrop-blur-xl z-50 flex items-start justify-center pt-24 sm:pt-32 md:pt-40 
        transition-all duration-500 ease-in-out"
            onClick={closeAllOverlays}
          >
            <div
              className="w-full max-w-md sm:max-w-xl md:max-w-2xl lg:max-w-3xl px-4 sm:px-6 md:px-8"
              onClick={(e) => e.stopPropagation()}
            >
              <div
                className="bg-white/90 backdrop-blur-sm rounded-3xl 
                shadow-[0_35px_60px_-15px_rgba(0,0,0,0.2)] 
                border border-white/10
                overflow-hidden
                transform transition-all duration-400"
              >
                <form
                  className="flex items-center space-x-4 p-4 sm:p-6 
                    border-b border-gray-100/30"
                  onSubmit={handleSearch}
                >
                  <div className="flex-grow flex items-center space-x-4 group">
                    <SearchIcon
                      className="w-6 h-6 text-gray-400 
                            transition-all duration-300 
                            group-focus-within:text-black"
                    />
                    <input
                      type="text"
                      placeholder="Discover products, collections, and more..."
                      className="w-full bg-transparent text-gray-900 
                            text-base  
                            px-2 sm:px-4 py-2 sm:py-3 
                            border-none focus:outline-none 
                            placeholder-gray-500 
                            transition-all duration-300
                            tracking-tight
                            font-light
                            selection:bg-black/10
                            no-zoom"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      autoComplete="off"
                    />
                  </div>
                  <button
                    type="submit"
                    className="text-white bg-black 
                        px-4 sm:px-6 py-2 sm:py-3 
                        rounded-2xl sm:rounded-3xl 
                        text-xs sm:text-sm
                        hover:bg-gray-900 
                        active:bg-gray-800
                        transition-all duration-400 
                        font-medium
                        shadow-xl hover:shadow-2xl
                        focus:outline-none focus:ring-4 focus:ring-black/20 focus:ring-offset-4
                        group
                        relative
                        overflow-hidden
                        flex items-center justify-center"
                  >
                    <span
                      className="absolute inset-0 bg-white/10 
                            opacity-0 group-hover:opacity-100 
                            transition-opacity duration-300"
                    />
                    <span className="relative z-10">Search</span>
                  </button>
                </form>
                {noResults && (
                  <p className="text-center text-red-500 py-4">
                    No matching results found. Please try a different search.
                  </p>
                )}
              </div>
            </div>
          </div>
        )}

        <AnimatePresence>
          {isSidebarOpen && (
            <>
              {/* Backdrop */}
              <motion.div
                className="fixed inset-0 bg-black/60 z-40"
                initial="hidden"
                animate="visible"
                exit="hidden"
                variants={backdropVariants}
                onClick={closeAllOverlays}
              />

              {/* Sidebar */}
              <motion.div
                className="fixed left-0 top-0 bottom-0 w-full sm:w-80 md:w-96 bg-white z-50 font-poppins flex flex-col"
                initial="hidden"
                animate="visible"
                exit="hidden"
                variants={sidebarVariants}
              >
                {/* Header */}
                <div
                  className={`relative p-6 border-b ${colors.border} bg-white`}
                >
                  {/* Close Button */}
                  <button
                    onClick={() => setIsSidebarOpen(false)}
                    className={`absolute top-4 right-4 p-4 rounded-full group transition-all duration-300 ease-in-out ${colors.hover}`}
                    style={{ zIndex: 50 }}
                  >
                    <X
                      className={`w-6 h-6 ${colors.secondary} group-hover:${colors.primary} transition-colors`}
                    />
                  </button>

                  {/* Logo */}
                  <Link
                    to="/"
                    className="flex justify-center mb-6 transform transition-transform duration-300 hover:scale-105"
                  >
                    <img
                      src="/images/SPARTANS.webp"
                      alt="Spartans Logo"
                      className="h-20 w-auto object-contain grayscale hover:grayscale-0 transition-all duration-500 ease-in-out"
                    />
                  </Link>
                </div>

                {/* Navigation */}
                <div className="flex-grow overflow-y-auto py-4 px-6 space-y-4 bg-gray-50 custom-scrollbar">
                  <nav className="space-y-2">
                    {/* Static Links */}
                    <NavLink
                      to="/"
                      className={({ isActive }) =>
                        `group flex items-center py-3 px-4 rounded-lg transition-all duration-300 ease-in-out ${
                          isActive
                            ? "bg-blue-100 text-blue-600 font-semibold"
                            : `${colors.secondary} ${colors.hover} hover:text-blue-600`
                        }`
                      }
                      onClick={closeAllOverlays}
                    >
                      <FaHome className="w-5 h-5 mr-3 group-hover:text-blue-600 transition-colors" />
                      <span>Home</span>
                    </NavLink>
                    <NavLink
                      to="/contact"
                      className={({ isActive }) =>
                        `group flex items-center py-3 px-4 rounded-lg transition-all duration-300 ease-in-out ${
                          isActive
                            ? "bg-blue-100 text-blue-600 font-semibold"
                            : `${colors.secondary} ${colors.hover} hover:text-blue-600`
                        }`
                      }
                      onClick={closeAllOverlays}
                    >
                      <FaEnvelope className="w-5 h-5 mr-3 group-hover:text-blue-600 transition-colors" />
                      <span>Contact Us</span>
                    </NavLink>

                    {/* Products Dropdown */}
                    <div className="relative">
                      <button
                        className={`w-full flex justify-between items-center py-3 px-4 rounded-lg ${colors.secondary} ${colors.hover} group transition-all duration-300 ease-in-out`}
                        onClick={toggleProductsDropdown}
                      >
                        <div className="flex items-center">
                          <FaBriefcase className="w-5 h-5 mr-3 group-hover:text-blue-600 transition-colors" />
                          <span className="font-medium group-hover:text-blue-600">
                            Products
                          </span>
                        </div>
                        <FaChevronDown
                          className={`w-5 h-5 transition-transform duration-300 ${
                            isProductsDropdownOpen
                              ? "rotate-180 text-blue-600"
                              : ""
                          }`}
                        />
                      </button>

                      {isProductsDropdownOpen && (
                        <div className="mt-2 space-y-2 pl-4 pr-4 bg-white rounded-lg shadow-md py-2">
                          {categories.map((category) => (
                            <div key={category._id} className="space-y-1">
                              {/* Category */}
                              <div className="text-gray-700 font-semibold mb-1 px-3">
                                {category.title}
                              </div>
                              {/* Subcategories */}
                              {category.subcategories.map((subcategory) => (
                                <NavLink
                                  key={subcategory._id}
                                  to={`/product?subcategory=${subcategory.title}`}
                                  className={({ isActive }) => `
                            block py-2 px-3 rounded-md text-sm 
                            transition-all duration-300 ease-in-out
                            ${
                              isActive
                                ? "bg-blue-100 text-blue-600 font-medium"
                                : "text-gray-500 hover:bg-blue-50 hover:text-blue-600 hover:translate-x-1"
                            }
                          `}
                                  onClick={closeAllOverlays}
                                >
                                  {subcategory.title}
                                </NavLink>
                              ))}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>

                    <NavLink
                      to="/about"
                      className={({ isActive }) =>
                        `group flex items-center py-3 px-4 rounded-lg transition-all duration-300 ease-in-out ${
                          isActive
                            ? "bg-blue-100 text-blue-600 font-semibold"
                            : `${colors.secondary} ${colors.hover} hover:text-blue-600`
                        }`
                      }
                      onClick={closeAllOverlays}
                    >
                      <FaUserFriends className="w-5 h-5 mr-3 group-hover:text-blue-600 transition-colors" />
                      <span>About Us</span>
                    </NavLink>
                  </nav>
                </div>
              </motion.div>
            </>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default Header;
